.slide-in-blurred-bottom {
	animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.puff-out-center {
	animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

.flip-in-ver-left {
	animation: flip-in-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center {
	animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.gradient-background {
	background: linear-gradient(300deg, #f50000, #b50ccc, #ffbe00);
	background-size: 180% 180%;
	animation: gradient-animation 18s ease infinite;
}

@keyframes slide-in-blurred-bottom {
	0% {
		transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
		transform-origin: 50% 100%;
		// filter: blur(40px);
		opacity: 0;
	}
	100% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		// filter: blur(0);
		opacity: 1;
	}
}

@keyframes puff-out-center {
	0% {
		transform: scale(1);
		filter: blur(0px);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		filter: blur(4px);
		opacity: 0;
	}
}

@keyframes flip-in-ver-left {
	0% {
		transform: rotateY(80deg);
		opacity: 0;
	}
	100% {
		transform: rotateY(0);
		opacity: 1;
	}
}

@keyframes scale-in-center {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
