@import "./vars.scss";
@import "./mixins.scss";

.button {
	@include normal;
	padding: 0.8rem 2rem;
	cursor: pointer;
	border-radius: 4px;
	transition: all 300ms ease-in-out;
	background-color: transparent;
	border: 1px solid black;
	color: black;

	&:hover {
		background-color: $primaryColor;
		color: white;
		border-color: darken($primaryColor, 20%);
	}
}
