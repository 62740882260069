@import "./vars.scss";
@import "./mixins.scss";
@import "./button.scss";

#chooseLang {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 1000;

	* {
		opacity: 0;
	}

	h1 {
		@include fancy;
		color: $primaryColor;
		margin-bottom: 2rem;
	}

	img {
		width: 100px;
	}
}
.choose-lang-button {
	@extend .button;
	margin: 0.5rem 0;
}
