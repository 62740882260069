@import "./assets/scss/vars.scss";
@import "./assets/scss/mixins.scss";
@import "./assets/scss/animations.scss";
@import "./assets/scss/chooseLang.scss";

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	@include normal;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: turquoise;
	overflow: hidden;
}

.headline {
	width: 100%;
	max-width: 800px;
	padding: 0 2rem;
	text-align: center;
	margin: auto auto 0;

	h1 {
		@include fancy();
		font-size: 2.5rem;
		color: $primaryColor;
	}
	p {
		color: white;
	}
}

.memory-game {
	width: min(90vw, 80vh);
	height: min(100vw, 70vh);
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	perspective: 2000px;
	padding-bottom: 2rem;
}

.memory-card {
	width: calc(25% - 10px);
	height: calc(33.333% - 10px);
	margin: 5px;
	border-radius: 5px;
	position: relative;
	transform: scale(1);
	transform-style: preserve-3d;
	transition: transform 0.5s;

	&:active {
		transform: scale(0.97);
		transition: transform 0.2s;
	}

	&:not(.flip):hover {
		background: rgba(255, 255, 255, 0.35);
	}

	&.flip {
		transform: rotateY(180deg);
	}
}

.front-face,
.back-face {
	width: 100%;
	height: 100%;
	padding: 5px;
	position: absolute;
	border-radius: 5px;
	background: lightgray;
	backface-visibility: hidden;
}

.front-face {
	transform: rotateY(180deg);
}

footer {
	padding: 1rem 1rem;
	font-size: 0.8rem;

	& > div {
		@include fancy;
		display: inline-block;
		margin: 0 0.5rem 0.5rem;
		background-color: rgba(0, 0, 0, 0.6);
		border-radius: 1rem;
		padding: 5px 10px;
		color: white;
	}
	& a {
		font-size: 0.7rem;
		@include normal;
		color: lightgray;
	}
}

#messagePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;

	&.hidden {
		display: none;
	}

	.content {
		transform: translateY(1000px);
		// @extend .glass-1;
		background-color: white;
		max-width: 600px;
		width: 80vw;
		text-align: center;
		border-radius: $popupRadius;
		box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
			rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
			rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

		.inner {
			padding: 2rem;

			h3 {
				font-size: 2rem;
				margin-top: 1.5rem;
			}
			h4 {
				font-size: 1.1rem;
			}
			.logo {
				width: 150px;
				margin-top: 2.5rem;
			}
		}

		.button-container {
			height: 65px;

			border-bottom-left-radius: $popupRadius;
			border-bottom-right-radius: $popupRadius;
		}
	}
}

.bottom-button {
	@include normal;
	font-size: 1rem;
	width: 100%;
	height: 100%;
	background-color: $primaryColor;
	color: white;
	cursor: pointer;
	border: 2px white solid;
	border-bottom-left-radius: $popupRadius;
	border-bottom-right-radius: $popupRadius;
	transition: all 400ms ease-out;

	&:hover {
		background-color: darken($primaryColor, 20%);
	}
}

#tiktokIframe {
	background: none transparent !important;
	max-width: 605px;
	min-width: 325px;
	border-radius: 1rem;
}

/* Image BG */
.image-background {
	background-color: white;
	background-image: url("img/pexels-ylanite-koppens-1693652.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}

/* glassmorphism */
.glass-1 {
	background: rgba(84, 251, 251, 0.6);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(9px);
	-webkit-backdrop-filter: blur(9px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}
.glass-2 {
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(11px);
	-webkit-backdrop-filter: blur(11px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

/* shadow */
.shadow {
	box-shadow: 0.2px 0.3px 0.5px rgba(0, 0, 0, 0.034),
		0.4px 0.7px 1.3px rgba(0, 0, 0, 0.048),
		0.8px 1.3px 2.4px rgba(0, 0, 0, 0.06),
		1.3px 2.2px 4.2px rgba(0, 0, 0, 0.072),
		2.5px 4.2px 7.9px rgba(0, 0, 0, 0.086), 6px 10px 19px rgba(0, 0, 0, 0.12);
}

.text-fancy {
	@include fancy;
}
