.slide-in-blurred-bottom {
  animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-bottom;
}

.puff-out-center {
  animation: 1s cubic-bezier(.165, .84, .44, 1) both puff-out-center;
}

.flip-in-ver-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-ver-left;
}

.scale-in-center {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-center;
}

.gradient-background {
  background: linear-gradient(300deg, #f50000, #b50ccc, #ffbe00) 0 0 / 180% 180%;
  animation: 18s infinite gradient-animation;
}

@keyframes slide-in-blurred-bottom {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: translateY(1000px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

@keyframes puff-out-center {
  0% {
    filter: blur();
    opacity: 1;
    transform: scale(1);
  }

  100% {
    filter: blur(4px);
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes flip-in-ver-left {
  0% {
    opacity: 0;
    transform: rotateY(80deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

@keyframes scale-in-center {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.button, .choose-lang-button {
  cursor: pointer;
  color: #000;
  background-color: #0000;
  border: 1px solid #000;
  border-radius: 4px;
  padding: .8rem 2rem;
  font-family: Roboto Slab, serif;
  transition: all .3s ease-in-out;
}

.button:hover, .choose-lang-button:hover {
  color: #fff;
  background-color: #6ec1cb;
  border-color: #38919b;
}

#chooseLang {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#chooseLang * {
  opacity: 0;
}

#chooseLang h1 {
  color: #6ec1cb;
  margin-bottom: 2rem;
  font-family: Mountains of Christmas, cursive;
}

#chooseLang img {
  width: 100px;
}

.choose-lang-button {
  margin: .5rem 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  background: #40e0d0;
  flex-direction: column;
  font-family: Roboto Slab, serif;
  display: flex;
  overflow: hidden;
}

.headline {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: auto auto 0;
  padding: 0 2rem;
}

.headline h1 {
  color: #6ec1cb;
  font-family: Mountains of Christmas, cursive;
  font-size: 2.5rem;
}

.headline p {
  color: #fff;
}

.memory-game {
  width: min(90vw, 80vh);
  height: min(100vw, 70vh);
  perspective: 2000px;
  flex-wrap: wrap;
  margin: auto;
  padding-bottom: 2rem;
  display: flex;
}

.memory-card {
  width: calc(25% - 10px);
  height: calc(33.333% - 10px);
  transform-style: preserve-3d;
  border-radius: 5px;
  margin: 5px;
  transition: transform .5s;
  position: relative;
  transform: scale(1);
}

.memory-card:active {
  transition: transform .2s;
  transform: scale(.97);
}

.memory-card:not(.flip):hover {
  background: #ffffff59;
}

.memory-card.flip {
  transform: rotateY(180deg);
}

.front-face, .back-face {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
}

.front-face {
  transform: rotateY(180deg);
}

footer {
  padding: 1rem;
  font-size: .8rem;
}

footer > div {
  color: #fff;
  background-color: #0009;
  border-radius: 1rem;
  margin: 0 .5rem .5rem;
  padding: 5px 10px;
  font-family: Mountains of Christmas, cursive;
  display: inline-block;
}

footer a {
  color: #d3d3d3;
  font-family: Roboto Slab, serif;
  font-size: .7rem;
}

#messagePopup {
  width: 100%;
  height: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#messagePopup.hidden {
  display: none;
}

#messagePopup .content {
  max-width: 600px;
  width: 80vw;
  text-align: center;
  background-color: #fff;
  border-radius: 1rem;
  transform: translateY(1000px);
  box-shadow: 0 54px 55px #00000040, 0 -12px 30px #0000001f, 0 4px 6px #0000001f, 0 12px 13px #0000002b, 0 -3px 5px #00000017;
}

#messagePopup .content .inner {
  padding: 2rem;
}

#messagePopup .content .inner h3 {
  margin-top: 1.5rem;
  font-size: 2rem;
}

#messagePopup .content .inner h4 {
  font-size: 1.1rem;
}

#messagePopup .content .inner .logo {
  width: 150px;
  margin-top: 2.5rem;
}

#messagePopup .content .button-container {
  height: 65px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.bottom-button {
  width: 100%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  background-color: #6ec1cb;
  border: 2px solid #fff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  font-family: Roboto Slab, serif;
  font-size: 1rem;
  transition: all .4s ease-out;
}

.bottom-button:hover {
  background-color: #38919b;
}

#tiktokIframe {
  max-width: 605px;
  min-width: 325px;
  border-radius: 1rem;
  background: none !important;
}

.image-background {
  background-color: #fff;
  background-image: url("pexels-ylanite-koppens-1693652.55e1b70d.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.glass-1 {
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  background: #54fbfb99;
  border: 1px solid #ffffff2e;
  border-radius: 10px;
  box-shadow: 0 8px 32px #1f26875e;
}

.glass-2 {
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  background: #ffffff59;
  border: 1px solid #ffffff2e;
  border-radius: 10px;
  box-shadow: 0 8px 32px #1f26875e;
}

.shadow {
  box-shadow: .2px .3px .5px #00000009, .4px .7px 1.3px #0000000c, .8px 1.3px 2.4px #0000000f, 1.3px 2.2px 4.2px #00000012, 2.5px 4.2px 7.9px #00000016, 6px 10px 19px #0000001f;
}

.text-fancy {
  font-family: Mountains of Christmas, cursive;
}

/*# sourceMappingURL=index.46d35a11.css.map */
